export default [
  { header: "Dashboards", icon: "mdi mdi-dots-horizontal" },
  {
    id: "1",
    icon: "crosshair",
    title: "Classic Dashboard",
    i18n: "Classic Dashboard",
    to: "/dashboard/classic-dashboard",
  },
  /*{
    id: "2",
    icon: "chrome",
    title: "Analytical Dashboard",
    i18n: "Analytical Dashboard",
    to: "/dashboard/analytical-dashboard",
  },
  {
    id: "3",
    icon: "shopping-bag",
    title: "Ecommerce Dashboard",
    i18n: "Ecommerce Dashboard",
    to: "/dashboard/ecommerce-dashboard",
  },
  {
    id: "4",
    icon: "triangle",
    title: "General Dashboard",
    i18n: "General Dashboard",
    to: "/dashboard/general-dashboard",
  },
  { id: "31", icon: "user", title: "Starter", i18n: "Starter", to: "/starter" },
  { header: "Apps", icon: "mdi mdi-dots-horizontal" },
  {
    id: "5",
    icon: "message-circle",
    title: "Chat",
    i18n: "Chat",
    to: "/apps/chat",
  },
  {
    id: "6",
    icon: "mail",
    title: "Email",
    i18n: "Email",
    to: "/apps/email/inbox",
  },
  {
    id: "7",
    icon: "phone",
    title: "Contact",
    i18n: "Contact",
    to: "/apps/contacts",
  },*/
  {
    id: "8",
    icon: "list",
    title: "Users",
    i18n: "Users",
    to: "/apps/contactslist",
  },
  {
    id: "81",
    icon: "book",
    title: "Courses",
    i18n: "Courses",
    to: "/apps/courseslist",
  },
  {
    id: "82",
    icon: "book-open",
    title: "Course Registrations",
    i18n: "Course Registrations",
    to: "/apps/registrationlist",
  },
  {
    id: "83",
    icon: "dollar-sign",
    title: "Payments",
    i18n: "Payments",
    to: "/apps/paymentlist",
  },
  {
    id: "84",
    icon: "repeat",
    title: "Withdrawls",
    i18n: "Withdrawls",
    to: "/apps/withdrawllist",
  },
  /*{
    id: "9",
    icon: "shopping-cart",
    title: "Ecommerce",
    i18n: "Ecommerce",
    to: "/apps/ecommerce",
  },
  {
    id: "10",
    icon: "pocket",
    title: "Treeview",
    i18n: "Treeview",
    to: "/apps/treeview",
  },
  {
    id: "1032",
    icon: "calendar",
    title: "Calendar",
    i18n: "Calendar",
    to: "/apps/calendar",
  },
  { header: "UI", icon: "mdi mdi-dots-horizontal" },
  {
    id: "11",
    icon: "cpu",
    title: "Ui Elements",
    i18n: "Ui Elements",
    name: "/ui-elements/",
    childern: [
      {
        icon: "mdi mdi-message-bulleted",
        title: "Alert",
        to: "/ui-elements/alert",
      },
      {
        icon: "mdi mdi-airplay",
        title: "Aspect",
        to: "/ui-elements/aspect",
      },
      {
        icon: "mdi mdi-account-box",
        title: "Avatar",
        to: "/ui-elements/avatar",
      },
      {
        icon: "mdi mdi-application",
        title: "Badge",
        to: "/ui-elements/badge",
      },
      {
        icon: "mdi mdi-equal",
        title: "Breadcrumb",
        to: "/ui-elements/breadcrumb",
      },
      {
        icon: "mdi mdi-toggle-switch",
        title: "Buttons",
        to: "/ui-elements/buttons",
      },
      {
        icon: "mdi mdi-checkerboard",
        title: "Button Group",
        to: "/ui-elements/button-group",
      },
      {
        icon: "mdi mdi-cards-variant",
        title: "Button Toolbar",
        to: "/ui-elements/button-toolbar",
      },
      {
        icon: "mdi mdi-cards-outline",
        title: "Cards",
        to: "/ui-elements/cards",
      },
      {
        icon: "mdi mdi-view-carousel",
        title: "Carousel",
        to: "/ui-elements/carousel",
      },
      {
        icon: "mdi mdi-bandcamp",
        title: "Collapse",
        to: "/ui-elements/collapse",
      },
      {
        icon: "mdi mdi-arrange-bring-to-front",
        title: "Dropdown",
        to: "/ui-elements/dropdown",
      },
      {
        icon: "mdi mdi-link-variant",
        title: "Embed",
        to: "/ui-elements/embed",
      },
      {
        icon: "mdi mdi-image-area",
        title: "Image",
        to: "/ui-elements/image",
      },
      {
        icon: "mdi mdi-library-books",
        title: "Jumbotron",
        to: "/ui-elements/jumbotron",
      },
      {
        icon: "mdi mdi-view-module",
        title: "Grid",
        to: "/ui-elements/grid",
      },
      {
        icon: "mdi mdi-file-video",
        title: "List Group",
        to: "/ui-elements/list-group",
      },
      {
        icon: "mdi mdi-image-filter",
        title: "Media",
        to: "/ui-elements/media",
      },
      {
        icon: "mdi mdi-tablet",
        title: "Modal",
        to: "/ui-elements/modal",
      },
      {
        icon: "mdi mdi-tab-unselected",
        title: "Nav",
        to: "/ui-elements/nav",
      },
      {
        icon: "mdi mdi-page-layout-header",
        title: "Navbar",
        to: "/ui-elements/navbar",
      },
      {
        icon: "mdi mdi-panorama-horizontal",
        title: "Overlay",
        to: "/ui-elements/overlay",
      },
      {
        icon: "mdi mdi-altimeter",
        title: "Pagination",
        to: "/ui-elements/pagination",
      },
      {
        icon: "mdi mdi-altimeter",
        title: "Pagination Nav",
        to: "/ui-elements/pagination-nav",
      },
      {
        icon: "mdi mdi-image-filter-vintage",
        title: "Popover",
        to: "/ui-elements/popover",
      },
      {
        icon: "mdi mdi-poll",
        title: "Progress",
        to: "/ui-elements/progress",
      },
      {
        icon: "mdi mdi-page-layout-sidebar-left",
        title: "Sidebar",
        to: "/ui-elements/sidebar",
      },
      {
        icon: "mdi mdi-tooltip-text",
        title: "Skeleton",
        to: "/ui-elements/skeleton",
      },
      {
        icon: "mdi mdi-apple-safari",
        title: "Spinner",
        to: "/ui-elements/spinner",
      },
      {
        icon: "mdi mdi-sort-variant",
        title: "Tabs",
        to: "/ui-elements/tabs",
      },
      {
        icon: "mdi mdi-timer",
        title: "Time",
        to: "/ui-elements/time",
      },
      {
        icon: "mdi mdi-credit-card-scan",
        title: "Toasts",
        to: "/ui-elements/toasts",
      },
      {
        icon: "mdi mdi-tooltip-outline",
        title: "Tooltip",
        to: "/ui-elements/tooltip",
      },
    ],
  },
  {
    id: "12",
    icon: "lock",
    title: "Authentication",
    i18n: "Authentication",
    name: "/authentication/",
    childern: [
      {
        icon: "mdi mdi-account-key",
        title: "Boxed Login",
        to: "/authentication/boxedlogin",
      },
      {
        icon: "mdi mdi-account-edit",
        title: "Boxed Register",
        to: "/authentication/boxedregister",
      },
      {
        icon: "mdi mdi-account-key",
        title: "Login",
        to: "/authentication/fulllogin",
      },
      {
        icon: "mdi mdi-account-edit",
        title: "Register",
        to: "/authentication/fullregister",
      },
      {
        icon: "mdi mdi-alert",
        title: "Error",
        to: "/authentication/error",
      },
    ],
  },
  { header: "Forms", icon: "mdi mdi-dots-horizontal" },
  {
    id: "13",
    icon: "layers",
    title: "Form Elements",
    i18n: "Form Elements",
    name: "/form-elements/",
    childern: [
      {
        icon: "mdi mdi-priority-low",
        title: "Form Inputs",
        to: "/form-elements/form-inputs",
      },
      {
        icon: "mdi mdi-code-equal",
        title: "Textarea",
        to: "/form-elements/textarea",
      },
      {
        icon: "mdi mdi-coins",
        title: "Select",
        to: "/form-elements/select",
      },
      {
        icon: "mdi mdi-checkbox-marked-outline",
        title: "Checkbox",
        to: "/form-elements/checkbox",
      },
      {
        icon: "mdi mdi-toggle-switch-off",
        title: "Switch",
        to: "/form-elements/switch",
      },
      {
        icon: "mdi mdi-radiobox-marked",
        title: "Radio",
        to: "/form-elements/radio",
      },
      {
        icon: "mdi mdi-calendar",
        title: "Datepicker",
        to: "/form-elements/datepicker",
      },
      {
        icon: "mdi mdi-clock-fast",
        title: "Timepicker",
        to: "/form-elements/timepicker",
      },
      {
        icon: "mdi mdi-file-document-box",
        title: "File",
        to: "/form-elements/file",
      },
      {
        icon: "mdi mdi-star-half",
        title: "Rating",
        to: "/form-elements/rating",
      },
      {
        icon: "mdi mdi-table-column-plus-after",
        title: "Spin Button",
        to: "/form-elements/spin-button",
      },
      {
        icon: "mdi mdi-tag-outline",
        title: "Tags",
        to: "/form-elements/tags",
      },
    ],
  },
  {
    id: "14",
    icon: "file-text",
    title: "Form Layouts",
    i18n: "Form Layouts",
    name: "/form-layouts/",
    childern: [
      {
        icon: "mdi mdi-rounded-corner",
        title: "Form Group",
        to: "/form-layouts/form-group",
      },
      {
        icon: "mdi mdi-vector-difference-ba",
        title: "Basic Form",
        to: "/form-layouts/form-basic",
      },
      {
        icon: "mdi mdi-code-equal",
        title: "Form Row Separator",
        to: "/form-layouts/form-row-separator",
      },
      {
        icon: "mdi mdi-flip-to-front",
        title: "Bordered Form",
        to: "/form-layouts/form-bordered",
      },
      {
        icon: "mdi mdi-file-document-box",
        title: "Horizontal Form",
        to: "/form-layouts/form-horizontal",
      },
      {
        icon: "mdi mdi-content-duplicate",
        title: "Striped Row",
        to: "/form-layouts/form-striped-row",
      },
    ],
  },
  {
    id: "15",
    icon: "box",
    title: "Form Wizard",
    i18n: "Form Wizard",
    to: "/form-layouts/form-wizard",
  },

  { header: "Widgets", icon: "mdi mdi-dots-horizontal" },
  {
    id: "16",
    icon: "maximize",
    title: "Drag n Drop",
    i18n: "Drag n Drop",
    to: "/widgets/drag-n-drop",
  },
  {
    id: "16.1",
    icon: "hard-drive",
    title: "Widget Apps",
    i18n: "Widget Apps",
    to: "/widgets/widget-apps",
  },
  {
    id: "16.2",
    icon: "grid",
    title: "Widget Data",
    i18n: "Widget Data",
    to: "/widgets/widget-data",
  },

  { header: "Tables", icon: "mdi mdi-dots-horizontal" },
  {
    id: "17",
    icon: "codepen",
    title: "Basic Table",
    i18n: "Basic Table",
    to: "/tables/basic-table",
  },
  {
    id: "18",
    icon: "repeat",
    title: "Pagination Table",
    i18n: "Pagination Table",
    to: "/tables/pagination-table",
  },
  {
    id: "19",
    icon: "server",
    title: "Row Select Table",
    i18n: "Row Select Table",
    to: "/tables/row-select-table",
  },
  {
    id: "20",
    icon: "maximize",
    title: "Row Detail Table",
    i18n: "Row Detail Table",
    to: "/tables/row-detail-table",
  },
  {
    id: "21",
    icon: "copy",
    title: "Sort Table",
    i18n: "Sort Table",
    to: "/tables/sort-table",
  },
  {
    id: "22",
    icon: "database",
    title: "Stacked Table",
    i18n: "Stacked Table",
    to: "/tables/stacked-table",
  },
  {
    id: "23",
    icon: "columns",
    title: "Sticky Header-Column Table",
    i18n: "Sticky Header-Column Table",
    to: "/tables/sticky-header-column-table",
  },
  {
    id: "24",
    icon: "alert-octagon",
    title: "Search Table",
    i18n: "Search Table",
    to: "/tables/search-table",
  },
  { header: "Chart", icon: "mdi mdi-dots-horizontal" },
  {
    id: "25",
    icon: "pie-chart",
    title: "Apex charts",
    i18n: "Apex charts",
    to: "/charts/apexcharts",
  },
  { header: "Icons", icon: "mdi mdi-dots-horizontal" },
  {
    id: "26",
    icon: "hexagon",
    title: "Font Awesome Icons",
    i18n: "Font Awesome Icons",
    to: "/icons/fontawesome",
  },
  {
    id: "27",
    icon: "smile",
    title: "Material Icons",
    i18n: "Material Icons",
    to: "/icons/material",
  },
  {
    id: "28",
    icon: "meh",
    title: "Simpleline Icons",
    i18n: "Simpleline Icons",
    to: "/icons/simpleline",
  },
  {
    id: "29",
    icon: "frown",
    title: "Themify Icons",
    i18n: "Themify Icons",
    to: "/icons/themify",
  },
  {
    id: "30",
    icon: "feather",
    title: "Feather Icons",
    i18n: "Feather Icons",
    to: "/icons/feather",
  },*/
];
